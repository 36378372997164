import CityIcon from '@assets/images/city-emoji.svg';
import HomeIcon from '@assets/images/home-emoji.svg';
import PetIcon from '@assets/images/pet-emoji.svg';
import InjuryIcon from '@assets/images/injury-emoji.svg';
import SportIcon from '@assets/images/sport-emoji.svg';
import SpiderIcon from '@assets/images/spider-emoji.svg';
import { TFunction } from 'i18next';
import {
  CREATE_SUBSCRIPTION,
  LANDING_PAGE_APARTMENT,
  LANDING_PAGE_HOUSE,
  LANDING_PAGE_MITE,
  LANDING_PAGE_NS,
  LANDING_PAGE_PETS,
  LANDING_PAGE_SPORT,
  Product,
} from '@shared/index';

export const getProducts = (t: TFunction) => [
  {
    icon: CityIcon,
    title: t('PRODUCTS:products.flat'),
    description: t('PRODUCTS:descriptions:flat'),
    rewardPercent: '30%',
    link: `${CREATE_SUBSCRIPTION}?product=${Product.APARTMENT}`,
    landingLink: LANDING_PAGE_APARTMENT,
  },
  {
    icon: HomeIcon,
    title: t('PRODUCTS:products.house'),
    description: t('PRODUCTS:descriptions:house'),
    rewardPercent: '30%',
    link: `${CREATE_SUBSCRIPTION}?product=${Product.HOUSE}`,
    landingLink: LANDING_PAGE_HOUSE,
  },
  {
    icon: PetIcon,
    title: t('PRODUCTS:products.pet'),
    description: t('PRODUCTS:descriptions:pet'),
    rewardPercent: '25%',
    link: `${CREATE_SUBSCRIPTION}?product=${Product.PETS}`,
    landingLink: LANDING_PAGE_PETS,
  },
  {
    icon: InjuryIcon,
    title: t('PRODUCTS:products.injury'),
    description: t('PRODUCTS:descriptions:injury'),
    rewardPercent: '30%',
    link: `${CREATE_SUBSCRIPTION}?product=${Product.NS}`,
    landingLink: LANDING_PAGE_NS,
  },
  {
    icon: SportIcon,
    title: t('PRODUCTS:products.sport'),
    description: t('PRODUCTS:descriptions:sport'),
    rewardPercent: '15%',
    link: `${CREATE_SUBSCRIPTION}?product=${Product.SPORT}`,
    landingLink: LANDING_PAGE_SPORT,
  },
  {
    icon: SpiderIcon,
    title: t('PRODUCTS:products.spider'),
    description: t('PRODUCTS:descriptions:mite'),
    rewardPercent: '15%',
    link: `${CREATE_SUBSCRIPTION}?product=${Product.MITE}`,
    landingLink: LANDING_PAGE_MITE,
  },
];
