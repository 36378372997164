import { styled, typography } from '@pulse-web-ui/theme';

export const WidgetHeader = styled.h2`
  ${typography.medium20};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const WidgetSubHeaderText = styled.h3`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 16px;
`;

export const PageHeader = styled.h1`
  ${typography.medium24};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 16px;
  padding: 0 16px;
`;
