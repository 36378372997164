import { useEffect } from 'react';
import { AxiosError } from 'axios';

import { ERROR } from '../constants';

interface Args<T> {
  res: BlobPart | undefined;
  isFetching: boolean;
  error: AxiosError<T> | null;
}

export const useOpenFile = <T>({ res, isFetching, error }: Args<T>) => {
  useEffect(() => {
    if (!isFetching && res) {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      window.open(url, '_blank');
    }
  }, [res, isFetching]);

  useEffect(() => {
    if (error) {
      window.open(ERROR, '_blank');
    }
  }, [error]);
};
