export const analyticEvents = {
  agentAuthOpen: 'agent_auth_open',
  agentAuthSignup: 'agent_auth_signup',
  agentRegOpen: 'agent_reg_open',
  agentRegTostepOtp: 'agent_reg_tostep_OTP',
  agentRegOtpOpen: 'agent_reg_OTP_open',
  agentRegComplited: 'agent_reg_complited',
  agentNewproduct: 'agent_newproduct', // isagent = n (наличие агентского договора n = нет договора)
  agentMoredetails: 'agent_moredetails', // isagent = n (наличие агентского договора n = нет договора)
  agentNewagreement: 'agent_newagreement',
  agentMainOpen: 'agent_main_open', // isagent = n isagent = y (наличие агентского договора n = нет договора)
  agentAgreementTostepDocs: 'agent_agreement_tostep_docs',
  agentNoagreementOpen: 'agent_noagreement_open',
  agentAgreementDocsOpen: 'agent_agreement_docs_open',
  agentAgreementTostepAdress: 'agent_agreement_tostep_adress',
  agentAgreementAdressOpen: 'agent_agreement_adress_open',
  agentAgreementTostepRequisites: 'agent_agreement_tostep_requisites',
  agentAgreementRequisitesOpen: 'agent_agreement_requisites_open',
  agentAgreementTostepForms: 'agent_agreement_tostep_forms',
  agentAgreementFormsOpen: 'agent_agreement_forms_open',
  agentAgreementChangedata: 'agent_agreement_changedata',
  agentAgreementAgreementForm: 'agent_agreement_agreement_form',
  agentAgreementTermsForm: 'agent_agreement_terms_form',
  agentAgreementPdnForm: 'agent_agreement_pdn_form',
  agentAgreementTostepSign: 'agent_agreement_tostep_sign',
  agentAgreementOtpOpen: 'agent_agreement_OTP_open',
  agentAgreementComplited: 'agent_agreement_complited',
  agentAgreementGotomain: 'agent_agreement_gotomain',
};
