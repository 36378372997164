import { makeAutoObservable } from 'mobx';

import { Main } from '@app/store';
import { AgentUser } from '../types';
import { AgentBill, AvailableSign } from '@entities/agent-contract';

//TODO: Удалить после объединения с AgentContractStore
export class AgentUserStore {
  private mainStore: Main;
  agent?: AgentUser;
  confirmToken?: string;
  agentBill?: AgentBill;
  availableSign?: AvailableSign;
  isShowCompletedRegistrationText?: boolean = false;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setAgent = (agent: AgentUser) => {
    this.agent = agent;
  };

  setConfirmToken = (token: string) => {
    this.confirmToken = token;
  };

  setAgentBill = (bill: AgentBill) => {
    this.agentBill = bill;
  };

  setAvailableSign = (data: AvailableSign) => {
    this.availableSign = data;
  };

  setIsShowCompletedRegistrationText = (value: boolean) => {
    this.isShowCompletedRegistrationText = value;
  };
}
