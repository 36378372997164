import { MouseEventHandler, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Checkbox } from '@pulse-web-ui/checkbox';

import {
  CommonResponse,
  ConsentType,
  FormControlContainer,
  analyticEvents,
  useOpenFile,
  useStores,
} from '@src/shared';
import { sendAnalyticEvent } from '@src/app/web-analytic';
import {
  useGetConsent,
  useGetContractDraft,
} from '@src/entities/agent-contract';

import { AgentContractAgreementsWrapper } from './agent-contract-agreements.styles';
import { AgentContractAgreementsProps } from '../model';

export const AgentContractAgreements = observer(() => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<AgentContractAgreementsProps>();
  const {
    MainStore: {
      agentContractAgreementsStore: {
        contractConditionsAgreement,
        personalDataManagement,
        setContractConditionsAgreement,
        setPersonalDataManagement,
      },
    },
  } = useStores();

  const {
    res: contractDraftRes,
    error: contractDraftError,
    refetch: refetchContractDraft,
    isFetching: isContractDraftFetching,
  } = useGetContractDraft();

  const {
    res: consentRes,
    error: consentError,
    refetch: refetchConsent,
    isFetching: isConsentFetching,
  } = useGetConsent(ConsentType.DistributionPersonalDataManagement);

  useOpenFile<CommonResponse>({
    res: contractDraftRes,
    error: contractDraftError,
    isFetching: isContractDraftFetching,
  });

  useOpenFile<CommonResponse>({
    res: consentRes,
    error: consentError,
    isFetching: isConsentFetching,
  });

  const handleContractConditionsAgreementClick: MouseEventHandler<
    HTMLAnchorElement
  > = (event) => {
    event.preventDefault();
    refetchContractDraft();
    sendAnalyticEvent(analyticEvents.agentAgreementTermsForm);
  };

  const handlePersonalDataManagementClick: MouseEventHandler<
    HTMLAnchorElement
  > = (event) => {
    event.preventDefault();
    refetchConsent();
    sendAnalyticEvent(analyticEvents.agentAgreementPdnForm);
  };

  useEffect(() => {
    setValue('contractConditionsAgreement', contractConditionsAgreement);
    setValue('personalDataManagement', personalDataManagement);
  }, []);

  return (
    <AgentContractAgreementsWrapper>
      <FormControlContainer>
        <Controller
          control={control}
          name="contractConditionsAgreement"
          render={({ field: { onChange, value }, fieldState }) => (
            <Checkbox
              id="contractConditionsAgreement"
              name="contractConditionsAgreement"
              label={
                <Trans
                  components={{
                    a: <a onClick={handleContractConditionsAgreementClick} />,
                  }}
                >
                  {t(
                    'AGENT_CONTRACT_AGREEMENTS:labels.agreeWithContractConditions'
                  )}
                </Trans>
              }
              checked={value}
              onChange={(event) => {
                onChange(event);
                setContractConditionsAgreement(event.target.checked);
              }}
              status={!!fieldState.error ? 'error' : undefined}
              message={errors.contractConditionsAgreement?.message}
            />
          )}
        />
      </FormControlContainer>
      <FormControlContainer margin="0 0 24px 0">
        <Controller
          control={control}
          name="personalDataManagement"
          render={({ field: { onChange, value }, fieldState }) => (
            <Checkbox
              id="personalDataManagement"
              name="personalDataManagement"
              label={
                <Trans
                  components={{
                    a: <a onClick={handlePersonalDataManagementClick} />,
                  }}
                >
                  {t(
                    'AGENT_CONTRACT_AGREEMENTS:labels.agreeWithPersonalDataManagement'
                  )}
                </Trans>
              }
              checked={value}
              onChange={(event) => {
                onChange(event);
                setPersonalDataManagement(event.target.checked);
              }}
              status={!!fieldState.error ? 'error' : undefined}
              message={errors.personalDataManagement?.message}
            />
          )}
        />
      </FormControlContainer>
    </AgentContractAgreementsWrapper>
  );
});
