export const AUTH = '/auth';
export const MAIN = '/';
export const ACTS = '/acts';
export const SALES = '/sales';
export const ABOUT = '/about';
export const CONTRACTS = '/contracts';
export const LOGOUT = '/logout';
export const OTP = '/otp';
export const PASSWORD_RECOVERY = '/password-recovery';
export const AGENT_CONTRACT = '/agent-contract';
export const NO_AGENT_CONTRACT = '/no-contract';
export const CONFIRM_CONTRACT = '/confirm-contract';
export const CREATE_SUBSCRIPTION = '/create-subscription';
export const PROFILE = '/profile';
export const ERROR = '/error';
export const CONTACTS = '/contacts';
export const STATUSES_INFO = '/statuses-info';
