import { Button } from '@pulse-web-ui/button';
import { media, styled, typography } from '@pulse-web-ui/theme';

import { PageHeader as Header } from '../headers';

export const PageHeader = styled(Header)`
  margin: 0;
  padding: 0;
`;

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 0 16px;

  ${media.desktop} {
    padding: 0 24px;
  }
`;

export const InfoButton = styled(Button)`
  ${typography.regular16};
`;
