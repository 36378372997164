import { ReactNode } from 'react';

import { WidgetHeader, WidgetSubHeaderText } from './';

interface Props {
  title: string;
  subTitle?: ReactNode | string;
}

export const WidgetHeaderWithSubText = ({ title, subTitle }: Props) => (
  <>
    <WidgetHeader style={{ marginBottom: !!subTitle ? 8 : 16 }}>
      {title}
    </WidgetHeader>
    {subTitle && <WidgetSubHeaderText>{subTitle}</WidgetSubHeaderText>}
  </>
);
