import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { useQueryFunction } from './use-query-function';
import { RequestParams } from '../types';
import { InstanceUrls } from '../utils/requests';

interface useRequestParams<TRequest> {
  params: RequestParams<TRequest>;
  instanceUrl?: InstanceUrls;
  config?: AxiosRequestConfig<TRequest>;
}

export const useRequest = <TRequest, TResponse, TError>({
  params,
  instanceUrl,
  config,
}: useRequestParams<TRequest>) => {
  const { key, method, url, request, deps, fetchManual } = params;
  const queryFn = useQueryFunction<TRequest, TResponse>(
    {
      method,
      url,
      request,
      requestConfig: config,
    },
    instanceUrl
  );

  const queryResult = useQuery<AxiosResponse<TResponse>, AxiosError<TError>>(
    [key, deps],
    queryFn,
    {
      enabled: fetchManual ? false : true,
      staleTime: 100_000_000,
    }
  );

  const {
    isLoading,
    error,
    data,
    refetch,
    isFetching,
    isRefetching,
    isFetchedAfterMount,
    status,
  } = queryResult;

  let res;

  if (!isFetching && data?.data) {
    res = data.data;
  }

  return {
    isLoading,
    error,
    res,
    refetch,
    isFetching,
    isRefetching,
    isFetchedAfterMount,
    status,
  };
};
