import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useErrorBoundary } from 'react-error-boundary';

import { SubmitButton } from '@src/shared/ui';

import {
  useStores,
  BoundaryErrorType,
  analyticEvents,
  removeSpaces,
} from '@src/shared';

import {
  UpdateProfileRequestParams,
  useUpdateProfile,
} from '@src/entities/agent-contract';
import { AgentBankDetailsFormParams } from '@src/entities/agent-bank-details/model';
import { sendAnalyticEvent } from '@src/app/web-analytic';

interface SubmitBankDetailsProps {
  onNextStep: () => void;
  isLoading?: boolean;
}

export const SubmitBankDetails = observer(
  ({ onNextStep, isLoading }: SubmitBankDetailsProps) => {
    const { t } = useTranslation();
    const { showBoundary } = useErrorBoundary();
    const {
      MainStore: {
        agentContractStore: { setBankDetails, profile },
      },
    } = useStores();
    const {
      handleSubmit,
      formState: { errors, isDirty },
    } = useFormContext<AgentBankDetailsFormParams>();
    const [requestParams, setRequestParams] =
      useState<UpdateProfileRequestParams>({
        isComplete: true,
        profile: {
          ...profile,
          bankDetails: undefined,
        },
      });

    const { res, refetch, error, isFetching } = useUpdateProfile(
      {
        ...requestParams,
      },
      [requestParams]
    );

    useEffect(() => {
      if (
        !!requestParams?.profile?.bankDetails?.bic?.length &&
        !!requestParams?.profile?.bankDetails?.nameBank?.length &&
        !!requestParams?.profile?.bankDetails?.corAccount?.length &&
        !!requestParams?.profile?.bankDetails?.bankAccount?.length
      ) {
        refetch();
      }
    }, [requestParams]);

    useEffect(() => {
      if (!isFetching && res) {
        if (!!requestParams?.profile?.bankDetails) {
          setBankDetails(requestParams?.profile?.bankDetails);
          sendAnalyticEvent(analyticEvents.agentAgreementTostepForms);
          onNextStep && onNextStep();
        }
      }
    }, [isFetching, res]);

    useEffect(() => {
      if (error) {
        showBoundary(BoundaryErrorType.SomethingWentWrong);
      }
    }, [error]);

    const submitPage = handleSubmit((values) => {
      const { bic, nameBank, bankAccount, corAccount } = values;

      if (!isDirty) {
        sendAnalyticEvent(analyticEvents.agentAgreementTostepForms);
        onNextStep && onNextStep();
      }

      setRequestParams((prevParams) => ({
        ...prevParams,
        profile: {
          ...prevParams.profile,
          bankDetails: {
            bic,
            nameBank,
            bankAccount: removeSpaces(bankAccount),
            corAccount: removeSpaces(corAccount),
          },
        },
      }));
    });

    return (
      <SubmitButton
        variant="primary"
        label={t('AGENT_BANK_DETAILS:buttons.createContract') || ''}
        onClick={submitPage}
        disabled={!!Object.keys(errors).length || isLoading}
        isLoading={isFetching}
      />
    );
  }
);
