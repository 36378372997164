import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import App from '@app/index';
import './assets/css/index.css';
import { UserAuthProvider } from '@contexts/user-context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserAuthProvider>
        <App />
      </UserAuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
