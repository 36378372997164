import { IconButton } from '@src/shared';
import { Card, Info, Subtitle } from './card-with-icon.styles';

interface CardWithIconProps {
  icon: string;
  title: string;
  subtitle: string;
}

export const CardWithIcon = ({ icon, title, subtitle }: CardWithIconProps) => (
  <Card>
    <IconButton>
      <img src={icon} />
    </IconButton>
    <Info>
      {title}
      <Subtitle>{subtitle}</Subtitle>
    </Info>
  </Card>
);
