export const PlatformType = 'WEB';

export enum AuthEndpoints {
  LOGIN = '/login',
  LOGOUT = '/logout',
}
export enum AgentEndpoints {
  AGENTS = '/api/external/agents/v1',
  AGENT_SALE = '/api/external/agent-sale/v1',
  AGENT_CONSENT = '/api/external/consent/v1/agent',
}

export enum DictionariesEndpoints {
  DICTIONARIES = '/api/common/v1/dictionaries',
}

export enum RequestMethods {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
}
