import { makeAutoObservable } from 'mobx';

import { Main } from '@app/store';

export class AgentContractAgreementsStore {
  private mainStore: Main;
  contractConditionsAgreement: boolean = false;
  personalDataManagement: boolean = false;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setContractConditionsAgreement = (val: boolean) => {
    this.contractConditionsAgreement = val;
  };

  setPersonalDataManagement = (val: boolean) => {
    this.personalDataManagement = val;
  };
}
