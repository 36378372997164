import { action } from 'mobx';
import { Option } from '@pulse-web-ui/select';
import { BicData } from '../../model';

export const getBicOptions = action(
  (bicData: BicData[] | undefined): Option[] | [] => {
    if (!bicData) return [];

    return bicData.map((item: BicData) => ({
      label: `${item.bic} (${item.name})`,
      value: item.bic,
    }));
  }
);
