/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import amplitude from 'amplitude-js';
import ym from 'react-yandex-metrika';

export const sendAnalyticEvent = (triggerEvent: string, params?: any): void => {
  ym('reachGoal', triggerEvent, params);
  amplitude.getInstance().logEvent(triggerEvent, params);
};
