import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useErrorBoundary } from 'react-error-boundary';
import { format } from 'date-fns';

import { SubmitButton } from '@src/shared/ui';
import { RegistrationFormProps } from '@src/entities/registration';
import { CreatePasswordFormProps } from '@features/create-password';
import {
  BoundaryErrorType,
  ConsentType,
  DateFormat,
  OTP,
  analyticEvents,
  getMismatches,
  phoneValueCasting,
  useStores,
} from '@src/shared';
import {
  useCreateAgent,
  CreateAgentRequestParams,
} from '@src/entities/agent-user';
import { AgreementsFormProps } from '@src/features/agreements';
import { FlkCode } from '@src/shared/constants/flk-code';
import { sendAnalyticEvent } from '@src/app/web-analytic';

const getConsents = ({
  personalDataAgreement,
  specialOffersAgreement,
}: AgreementsFormProps) => {
  const consents = [];

  if (personalDataAgreement) {
    consents.push({
      consentType: ConsentType.AgentDataManagement,
      approvedAt: new Date().toISOString(),
    });
    consents.push({
      consentType: ConsentType.ElectronicDocumentManagement,
      approvedAt: new Date().toISOString(),
    });
  }

  if (specialOffersAgreement) {
    consents.push({
      consentType: ConsentType.AgentActiveCommunication,
      approvedAt: new Date().toISOString(),
    });
  }

  return consents;
};

export const SubmitRegistration = observer(() => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const {
    MainStore: {
      agentUserStore: { setAgent, setConfirmToken },
    },
  } = useStores();
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = useFormContext<
    RegistrationFormProps & CreatePasswordFormProps & AgreementsFormProps
  >();
  const navigate = useNavigate();
  const [requestParams, setRequestParams] = useState<CreateAgentRequestParams>({
    agent: {
      lastName: '',
      firstName: '',
      middleName: '',
      birthDate: '',
      phone: '',
      email: '',
      password: '',
    },
    consents: [],
  });

  const { res, refetch, error, isFetching } = useCreateAgent(
    {
      ...requestParams,
    },
    [requestParams]
  );

  useEffect(() => {
    if (!!requestParams.agent.lastName?.length) {
      refetch();
    }
  }, [requestParams]);

  useEffect(() => {
    if (!isFetching && res) {
      setAgent(res.agent);
      setConfirmToken(res.token);
      sendAnalyticEvent(analyticEvents.agentRegTostepOtp);
      navigate(OTP);
    }
  }, [isFetching, res]);

  useEffect(() => {
    if (error) {
      const mismatches = getMismatches(error);

      if (!!mismatches) {
        let hasKnownErrors = false;
        mismatches.forEach(({ code }) => {
          if (code === FlkCode.RMM_6010_0005) {
            // Пользователь с таким email существует
            setError('email', {
              message: t('COMMON:errors.emailAlreadyExist'),
            });
            hasKnownErrors = true;
          } else if (
            code === FlkCode.RMM_6010_0006 ||
            code === FlkCode.RMM_6010_0007
          ) {
            // Неккоректный телефон
            setError('phone', {
              message: t('COMMON:errors.incorrectPhoneNumber'),
            });
            hasKnownErrors = true;
          } else if (code === FlkCode.AGT_0101_0002) {
            // Пользователь с таким телефоном существует
            setError('phone', {
              message: t('COMMON:errors.phoneAlreadyExist'),
            });
            hasKnownErrors = true;
          } else if (
            code === FlkCode.RMM_6010_0004 ||
            code === FlkCode.RMM_6010_0012 ||
            code === FlkCode.RMM_6010_0013 ||
            code === FlkCode.RMM_6010_0014
          ) {
            // Неккоректный пароль
            setError('password', {
              message: t('PASSWORD:errors.invalidCharacters'),
            });
            hasKnownErrors = true;
          }
        });

        if (!hasKnownErrors) {
          showBoundary(BoundaryErrorType.SomethingWentWrong);
        }
      } else {
        showBoundary(BoundaryErrorType.SomethingWentWrong);
      }
    }
  }, [error]);

  const submitPage = handleSubmit((values) => {
    const {
      lastName,
      firstName,
      middleName,
      birthDate,
      phone,
      email,
      personalDataAgreement,
      specialOffersAgreement,
      password,
    } = values;

    setRequestParams({
      agent: {
        lastName,
        firstName,
        middleName,
        birthDate: format(new Date(birthDate), DateFormat.YYYY_MM_DD),
        phone: phoneValueCasting(phone),
        email,
        password,
      },
      consents: getConsents({
        personalDataAgreement,
        specialOffersAgreement,
      }),
    });
  });

  return (
    <SubmitButton
      variant="primary"
      label={t('COMMON:buttons.continue') || ''}
      onClick={submitPage}
      disabled={!!Object.keys(errors).length}
    />
  );
});
