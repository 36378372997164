import { styled, css, media } from '@pulse-web-ui/theme';

export const FormControlContainer = styled.div<{
  margin?: string;
}>`
  width: 100%;
  margin-bottom: 12px;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const TabsContainer = styled.div`
  margin-bottom: 8px;
`;

export const StepsListContainer = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 0 24px;
  box-sizing: border-box;

  ${media.desktop} {
    padding: 0;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContainerWithPadding = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 0px 16px;
  box-sizing: border-box;
`;
