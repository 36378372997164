import { makeAutoObservable, configure } from 'mobx';
import { AgentUserStore } from '@entities/agent-user';
import { AgentContractStore } from '@entities/agent-contract';
import { DictionariesStore } from '@entities/dictionaries';
import { AgentContractAgreementsStore } from '@features/agent-contract-agreements';

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
  disableErrorBoundaries: true,
});

class Main {
  public agentUserStore: AgentUserStore;
  public agentContractStore: AgentContractStore;
  public dictionariesStore: DictionariesStore;
  public agentContractAgreementsStore: AgentContractAgreementsStore;

  constructor() {
    this.agentUserStore = new AgentUserStore(this);
    this.agentContractStore = new AgentContractStore(this);
    this.dictionariesStore = new DictionariesStore(this);
    this.agentContractAgreementsStore = new AgentContractAgreementsStore(this);
    makeAutoObservable(this);
  }
}

export { Main };
