import { styled, typography } from '@pulse-web-ui/theme';

export const Label = styled.div`
  ${typography.regular12};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Value = styled.div`
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const LabelValueList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
