import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  MAIN,
  // ACTS,
  CONTRACTS,
  AGENT_CONTRACT,
  NO_AGENT_CONTRACT,
  CONFIRM_CONTRACT,
  CREATE_SUBSCRIPTION,
  PROFILE,
  ERROR,
  ABOUT,
  CONTACTS,
  STATUSES_INFO,
  SALES,
} from '@shared/constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@src/shared';

// const ActsPage = lazy(() => import('./acts'));
const ContractsListPage = lazy(() => import('./contracts-list'));
const MainPage = lazy(() => import('./main'));
const AgentContractPage = lazy(() => import('./agent-contract'));
const NoAgentContractPage = lazy(() => import('./no-agent-contract'));
const ConfirmContractPage = lazy(() => import('./confirm-contract'));
const CreateSubscriptionPage = lazy(() => import('./create-subscription'));
const ProfilePage = lazy(() => import('./profile'));
const ProductsPage = lazy(() => import('./products'));
const ErrorPage = lazy(() => import('./error'));
const ContactsPage = lazy(() => import('./contacts'));
const StatusesInfoPage = lazy(() => import('./statuses-info'));
const SalesPage = lazy(() => import('./sales'));
//TODO: Открыть меню Акты после MVP
const Routing = observer(() => {
  const {
    MainStore: {
      agentContractStore: { profile },
      agentUserStore: { confirmToken },
    },
  } = useStores();

  return (
    <Routes>
      <Route path={MAIN} element={<MainPage />} />
      {/* <Route path={ACTS} element={<ActsPage />} /> */}
      <Route path={CONTRACTS} element={<ContractsListPage />} />
      <Route path={AGENT_CONTRACT} element={<AgentContractPage />} />
      <Route path={NO_AGENT_CONTRACT} element={<NoAgentContractPage />} />
      <Route path={CREATE_SUBSCRIPTION} element={<CreateSubscriptionPage />} />
      <Route path={PROFILE} element={<ProfilePage />} />
      <Route path={ABOUT} element={<ProductsPage />} />
      <Route path={CONTACTS} element={<ContactsPage />} />
      <Route path={STATUSES_INFO} element={<StatusesInfoPage />} />
      <Route path={SALES} element={<SalesPage />} />
      {!!profile?.phone && !!confirmToken && (
        <Route path={CONFIRM_CONTRACT} element={<ConfirmContractPage />} />
      )}
      <Route path={ERROR} element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});

export default Routing;
