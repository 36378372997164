import { makeAutoObservable, runInAction } from 'mobx';

import { Main } from '@app/store';

import { BicData } from '..';

export class DictionariesStore {
  private mainStore: Main;
  bicDictionary?: BicData[];
  isBicDictionaryLoaded?: boolean;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setBicDictionary = (dictionary: BicData[]) => {
    runInAction(() => {
      this.bicDictionary = dictionary;
      this.isBicDictionaryLoaded = true;
    });
  };
}
