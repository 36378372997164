import agentSalesListDictionary from '@widgets/agent-sales-list/i18n/ru/agent-sales-list.dic.json';
import salesEmptyStateDictionaty from '@widgets/sales-empty-state/i18n/ru/sales-empty-state.dic.json';

export const agentSalesList = {
  AGENT_SALES_LIST: agentSalesListDictionary,
  SALES_EMPTY_STATE: salesEmptyStateDictionaty,
};

export const agentSalesListNamespaces = [
  'AGENT_SALES_LIST',
  'SALES_EMPTY_STATE',
];
