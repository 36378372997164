import { Alert } from '@pulse-web-ui/alert';
import { ProductList } from '@src/entities/product-list';
import {
  LANDING_PAGE_APARTMENT,
  NO_AGENT_CONTRACT,
  WidgetHeaderWithSubText,
  analyticEvents,
  useStores,
} from '@src/shared';
import { useTranslation } from 'react-i18next';
import {
  AlertButton,
  AlertText,
  Container,
  MoreButton,
  RewardHint,
} from './choose-product.styles';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { sendAnalyticEvent } from '@src/app/web-analytic';

export const ChooseProduct = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    MainStore: {
      agentUserStore: { availableSign },
    },
  } = useStores();

  const goToNoContractsPage = () => {
    sendAnalyticEvent(analyticEvents.agentNewagreement);
    navigate(NO_AGENT_CONTRACT);
  };

  return (
    <Container>
      {availableSign?.visibilityDocuments && (
        <Alert
          type="warning"
          description={
            <>
              <AlertText>{t('CHOOSE_PRODUCT:alerts.contract')}</AlertText>
              <AlertButton
                label={t('CHOOSE_PRODUCT:buttons.sign')}
                variant="text"
                onClick={goToNoContractsPage}
              />
            </>
          }
        />
      )}
      <div>
        <WidgetHeaderWithSubText
          title={t('CHOOSE_PRODUCT:headers.newProduct')}
        />
        <ProductList />
        <MoreButton
          label={t('CHOOSE_PRODUCT:buttons.moreAboutProducts')}
          variant="text"
          onClick={() => {
            sendAnalyticEvent(analyticEvents.agentMoredetails, {
              isagent: availableSign?.visibilityDocuments ? 'n' : 'y',
            });
            window.open(LANDING_PAGE_APARTMENT, '_blank');
          }}
        />
        <RewardHint>{t('CHOOSE_PRODUCT:hints.rewardHint')}</RewardHint>
      </div>
    </Container>
  );
});
