import {
  NO_AGENT_CONTRACT,
  analyticEvents,
  useStores,
  CardWithIcon,
  getProducts,
} from '@src/shared';

import { List, ProductLink } from './product-list.styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { sendAnalyticEvent } from '@src/app/web-analytic';

export const ProductList = observer(() => {
  const { t } = useTranslation();
  const {
    MainStore: {
      agentUserStore: { availableSign },
    },
  } = useStores();

  return (
    <List>
      {getProducts(t).map(({ icon, title, rewardPercent, link }) => (
        <ProductLink
          onClick={() => {
            sendAnalyticEvent(analyticEvents.agentNewproduct, {
              isagent: availableSign?.visibilityDocuments ? 'n' : 'y',
            });
          }}
          key={title}
          to={availableSign?.visibilityDocuments ? NO_AGENT_CONTRACT : link}
        >
          <CardWithIcon icon={icon} title={title} subtitle={rewardPercent} />
        </ProductLink>
      ))}
    </List>
  );
});
