import {
  getCurrentUserInfo,
  LocalStorageUserInfoKeys,
} from './user-context-utils';

let currentUserLogoutTimerId: number | undefined;

let logoutActions: () => void;

export const injectAutoLogoutActions = (actions: typeof logoutActions) => {
  logoutActions = actions;
};
export const setUserSessionAutoLogout = (): number => {
  clearTimeout(currentUserLogoutTimerId);
  const { sessionExpDate } = getCurrentUserInfo();
  const sessionExpiredTime = new Date().getTime() - sessionExpDate.getTime();

  const sessionTimeOutTimer = 0 > sessionExpiredTime ? 0 : sessionExpiredTime;
  const timerId = window.setTimeout(() => {
    if (logoutActions) {
      logoutActions();
    }
  }, sessionTimeOutTimer);
  currentUserLogoutTimerId = timerId;

  return timerId;
};

export const handleTokensEpxUpdate = (
  accessTokenExp: string | undefined,
  refreshTokenExp: string | undefined
) => {
  if (accessTokenExp) {
    localStorage.setItem(LocalStorageUserInfoKeys.acc_exp, accessTokenExp);
  }
  if (refreshTokenExp) {
    localStorage.setItem(LocalStorageUserInfoKeys.ref_exp, refreshTokenExp);
    setUserSessionAutoLogout();
  }
};

window.addEventListener('storage', (e) => {
  const { key } = e;

  if (key === null) {
    clearTimeout(currentUserLogoutTimerId);

    if (logoutActions) {
      logoutActions();
    }
  } else if (key === LocalStorageUserInfoKeys.ref_exp) {
    setUserSessionAutoLogout();
  }
});
